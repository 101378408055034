import { ThemeProvider } from '@leshen/ui'
import React from 'react'
import GlobalStyles from '../../../../gatsby-theme-frontier/src/components/CustomStyles/GlobalStyles'
import siteTheme from '../../theme'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>
    <GlobalStyles theme={siteTheme} />
    {children}
  </ThemeProvider>
)

export default Wrapper
